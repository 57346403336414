


































import { Component, Ref, Vue } from 'vue-property-decorator';
import { validators } from '@/utils/validate';
import { UserModule } from '@/store/modules/user';

@Component({
    name: 'UserLogin',
    components: {}
})
export default class UserLogin extends Vue {
    @Ref('form') private readonly formRef: any;

    public form: any = {
        UserId: '',
        PassWord: ''
    }

    public loading: boolean = false;

    public rules: any = {
        UserId: [validators.required],
        PassWord: [validators.required]
    }

    public async handleLogin() {
        try {
            this.loading = true;
            await this.formRef.validate();
            await UserModule.Login(this.form);
            await this.$router.push({
                name: 'CarryPoint'
            });
        } catch (err) {
            console.warn(err);
        } finally {
            this.loading = false;
        }
    }
}
