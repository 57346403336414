export const calcDate = (date1: any, date2: any) => {
    const date3 = date2 - date1;

    const days = Math.floor(date3 / (24 * 3600 * 1000));

    // 计算天数后剩余的毫秒数
    const leave1 = date3 % (24 * 3600 * 1000);
    const hours = Math.floor(leave1 / (3600 * 1000));

    // 计算小时数后剩余的毫秒数
    const leave2 = leave1 % (3600 * 1000);
    const minutes = Math.floor(leave2 / (60 * 1000));

    // 计算分钟数后剩余的毫秒数
    const leave3 = leave2 % (60 * 1000);
    const seconds = Math.round(date3 / 1000);
    return {
        leave1,
        leave2,
        leave3,
        days: days,
        hours: hours,
        minutes: minutes,
        seconds: seconds
    };
};
/**
 * 日期格式化
 */
export const dateFormat: any = (date: any) => {
    let format = 'yyyy-MM-dd hh:mm:ss';
    if (date !== 'Invalid Date') {
        const o: any = {
            'M+': date.getMonth() + 1, // month
            'd+': date.getDate(), // day
            'h+': date.getHours(), // hour
            'm+': date.getMinutes(), // minute
            's+': date.getSeconds(), // second
            'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
            'S+': date.getMilliseconds() // millisecond
        };
        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1,
                (date.getFullYear() + '').substr(4 - RegExp.$1.length));
        }
        for (const k in o) {
            if (new RegExp('(' + k + ')').test(format)) {
                format = format.replace(RegExp.$1,
                    RegExp.$1.length === 1
                    ? o[k]
                    : ('00' + o[k]).substr(('' + o[k]).length));
            }
        }
        return format;
    }
    return '';
};
