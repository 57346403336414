



























import { Component, Vue } from 'vue-property-decorator';
import { dateFormat } from '@/utils/date';
import userLogin from './userlogin.vue';

@Component({
    name: 'Login',
    components: {
        userLogin
    }
})
export default class Login extends Vue {
    public loading = false;

    public time = dateFormat(new Date());

    mounted() {
        this.getTime();
    }

    private getTime() {
        setInterval(() => {
            this.time = dateFormat(new Date());
        }, 1000);
    }
}
